import React from 'react'
import "./Screen.css";

const Screen = ({ value }) => {
  return (
    <section className='screen'>
      { value }
    </section>
  )
}

export default Screen