import React, { useState } from 'react'
import "./ThemeMenu.scss";

const ThemeMenu = (props) => {
  const { themeSelection, toggleThemeSelection, placeholder } = props

  return (
    <section className='main-theme-wrapper'>
      <select 
        value={themeSelection}
        onChange={toggleThemeSelection}
      >
        <option disabled>Theme</option>
        <option value="main">Main</option>
        <option value="secondary">Secondary</option>
        <option value="hand">Hand Drawn</option>
      </select>
    </section>
  )
}

export default ThemeMenu